<template>
  <div class="container">
    <div class="p-title">Available Loads</div>
    <form @submit.prevent="getAvailableLoads">
      <div class="loads-card">
        <div class="lg:flex gap-3 mb-3">
          <div class="lg:w-9/12 gap-3">
            <div class="available-loads-card mb-5">
              <div class="lg:flex gap-3">
                <div class="origin-wrapper">
                  <div class="icon-wrapper">
                    <svg class="icon">
                      <use xlink:href="#map-marker"></use>
                    </svg>
                  </div>
                  Origin
                </div>
                <div class="lg:w-4/12">
                  <div class="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="OriginCity"
                    />
                  </div>
                </div>
                <div class="lg:w-4/12">
                  <div class="form-group">
                    <label>State</label>
                    <select
                      id="txtOriginState"
                      class="custom-select"
                      v-model="OriginState"
                    >
                      <option value="" selected="selected">
                        Select a state
                      </option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                      <option value="AB">Alberta</option>
                      <option value="BC">British Columbia</option>
                      <option value="MB">Manitoba</option>
                      <option value="NB">New Brunswick</option>
                      <option value="NL">Newfoundland and Labrador</option>
                      <option value="NS">Nova Scotia</option>
                      <option value="ON">Ontario</option>
                      <option value="PE">Prince Edward Island</option>
                      <option value="QC">Quebec</option>
                      <option value="SK">Saskatchewan</option>
                      <option value="NT">Northwest Territories</option>
                      <option value="NU">Nunavut</option>
                      <option value="YT">Yukon</option>
                    </select>
                  </div>
                </div>
                <div class="lg:w-4/12">
                  <div class="form-group">
                    <label>Radius</label>
                    <select
                      id="txtOriginRadius"
                      class="custom-select"
                      v-model="OriginRadius"
                    >
                      <option value="0" selected="selected">
                        Select a radius
                      </option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="destination-loads-card">
              <div class="lg:flex gap-3">
                <div class="destination-wrapper">
                  <div class="icon-wrapper">
                    <svg class="icon">
                      <use xlink:href="#map-marker"></use>
                    </svg>
                  </div>
                  Destination
                </div>
                <div class="lg:w-4/12">
                  <div class="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="DestinationCity"
                    />
                  </div>
                </div>
                <div class="lg:w-4/12">
                  <div class="form-group">
                    <label>State</label>
                    <select
                      id="txtDestinationState"
                      class="custom-select"
                      v-model="DestinationState"
                    >
                      <option value="" selected="selected">
                        Select a state
                      </option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                      <option value="AB">Alberta</option>
                      <option value="BC">British Columbia</option>
                      <option value="MB">Manitoba</option>
                      <option value="NB">New Brunswick</option>
                      <option value="NL">Newfoundland and Labrador</option>
                      <option value="NS">Nova Scotia</option>
                      <option value="ON">Ontario</option>
                      <option value="PE">Prince Edward Island</option>
                      <option value="QC">Quebec</option>
                      <option value="SK">Saskatchewan</option>
                      <option value="NT">Northwest Territories</option>
                      <option value="NU">Nunavut</option>
                      <option value="YT">Yukon</option>
                    </select>
                  </div>
                </div>
                <div class="lg:w-4/12">
                  <div class="form-group">
                    <label>Radius</label>
                    <select
                      id="txtDestinationRadius"
                      class="custom-select"
                      v-model="DestinationRadius"
                    >
                      <option value="0">Select a radius</option>
                      <option value="100" selected="selected">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="lg:w-3/12">
            <div class="load-card">
              <div class="flex">
                <div class="origin-wrapper">Load</div>
                <div class="lg:w-12/12">
                  <label for="PickupDate">Pickup Date</label>
                  <Calendar
                    id="PickupDate"
                    v-model="PickupDate"
                    class="form-control"
                    :showButtonBar="true"
                    :showIcon="true"
                  />
                </div>
              </div>
              <div class="w-full flex">
                <div class="form-group lg:w-4/12 text-center mt-3">
                  <input type="checkbox" v-model="Van" />
                  <label class="flex justify-center"> Van </label>
                </div>
                <div class="form-group lg:w-4/12 text-center mt-3">
                  <input type="checkbox" v-model="Flatbed" />
                  <label class="flex justify-center"> Flatbed </label>
                </div>
                <div class="form-group lg:w-4/12 text-center mt-3">
                  <input type="checkbox" v-model="Reefer" />
                  <label class="flex justify-center"> Reefer </label>
                </div>
              </div>
              <div class="flex">
                <div class="form-group lg:w-4/12 text-center mt-3">
                  <input type="checkbox" v-model="Specialized" />
                  <label class="flex justify-center"> Specialized </label>
                </div>
                <div class="form-group lg:w-4/12 text-center mt-3">
                  <input type="checkbox" v-model="Size48" />
                  <label class="flex justify-center"> Size48 </label>
                </div>
                <div class="form-group lg:w-4/12 text-center mt-3">
                  <input type="checkbox" v-model="Size53" />
                  <label class="flex justify-center"> Size53 </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper1 text-center">
          <button class="main-btn mr-2" type="submit">
            Search
          </button>
          <button class="btn mr-2" type="button" @click="clearSearch()">
            Clear
          </button>
        </div>
      </div>
    </form>
    <br />
    <br />
    <div v-if="loading">
      <LoaderSkeleton></LoaderSkeleton>
      <LoaderSkeleton></LoaderSkeleton>
      <LoaderSkeleton></LoaderSkeleton>
      <LoaderSkeleton></LoaderSkeleton>
      <LoaderSkeleton></LoaderSkeleton>
    </div>
    <div class="table-responsive">
      <DataTable
        :value="availableLoads"
        :paginator="true"
        :rows="10"
        sortMode="multiple"
        v-if="isSuccessfull"
        ref="dt"
        v-model:filters="filters1"
        filterDisplay="menu"
        v-show="!this.loading"
      >
        <template #header>
          <div class="flex justify-between items-center">
            <div class="no-loads mb-3">{{ responseText }}</div>
            <div>
              <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                />
              </span>
              <Button
                type="button"
                icon="pi pi-filter-slash"
                label="Clear"
                class="p-button-outlined"
                @click="clearFilter1()"
              />
            </div>
          </div>
        </template>
        <Column field="LoadID" header="Load #" :sortable="true"></Column>
        <Column
          field="PickupDate"
          header="Pickup date"
          sortable
          dataType="date"
        >
          <template #body="{ data }">
            {{
              data.PickupDate == "0001-01-01T00:00:00"
                ? null
                : formatDate(data.PickupDate)
            }}
          </template>
        </Column>
        <Column
          field="OriginCity"
          header="Origin City"
          :sortable="true"
        ></Column>
        <Column
          field="OriginState"
          header="Origin State"
          :sortable="true"
        ></Column>
        <Column
          field="DestinationDate"
          header="Del Date"
          :sortable="true"
          dataType="date"
        >
          <template #body="{ data }">
            {{
              data.DestinationDate == "0001-01-01T00:00:00"
                ? null
                : formatDate(data.DestinationDate)
            }}
          </template>
        </Column>
        <Column
          field="DestinationCity"
          header="Dest City"
          :sortable="true"
        ></Column>
        <Column
          field="DestinationState"
          header="Destination State"
          :sortable="true"
        ></Column>
        <Column field="Weight" header="Weight" :sortable="true"></Column>
        <Column field="Trailer" header="Trailer" :sortable="true"></Column>
        <Column field="LoadMileage" header="Load Mileage" :sortable="true">
          <template #body="{ data }">
            {{ Math.round(data.LoadMileage) }}
          </template>
        </Column>
        <Column
          field="ContactName"
          header="Contact Name"
          :sortable="true"
        ></Column>
        <Column
          field="ContactPhone"
          header="Contact Phone"
          :sortable="true"
        ></Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { dataProvider } from "@/api/dataProvider";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import LoaderSkeleton from "@/components/common/LoaderSkeleton.vue";
export default {
  components: {
    InputText,
    Calendar,
    LoaderSkeleton,
  },
  data() {
    return {
      filters1: null,
      CarrierID: this.$store.state.login.CarrierID,
      CarrierPortalUserID: this.$store.state.login.CarrierPortalUserID,
      EmailAddress: this.$store.state.login.EmailAddress,
      availableLoads: [],
      responseText: "",
      isSuccessfull: false,
      OriginCity: "",
      OriginState: "",
      OriginRadius: 0,
      DestinationCity: "",
      DestinationState: "",
      DestinationRadius: 0,
      PickupDate: null,
      Van: false,
      Flatbed: false,
      Reefer: false,
      Specialized: false,
      Size48: false,
      Size53: false,
      loading: false,
    };
  },
  created() {
    this.initFilters1();
  },
  methods: {
    formatDate(value) {
      return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    async getAvailableLoads() {
      this.loading = true;
      var AvailableLoads = await dataProvider.getAvailableLoads(
        this.CarrierID,
        this.CarrierPortalUserID,
        this.EmailAddress,
        this.OriginCity,
        this.OriginState,
        this.OriginRadius,
        this.DestinationCity,
        this.DestinationState,
        this.DestinationRadius,
        this.PickupDate != null
          ? this.formatDate(this.PickupDate)
          : this.PickupDate,
        this.Van,
        this.Flatbed,
        this.Reefer,
        this.Specialized,
        this.Size48,
        this.Size53
      );

      this.responseText = AvailableLoads.ResponseText;
      this.availableLoads = AvailableLoads.AvailableLoads;
      this.isSuccessfull = AvailableLoads.IsSuccessful;
      this.loading = false;
    },
    clearSearch() {
      (this.OriginCity = ""),
        (this.OriginState = ""),
        (this.OriginRadius = 0),
        (this.DestinationCity = ""),
        (this.DestinationState = ""),
        (this.DestinationRadius = 0),
        (this.PickupDate = null),
        (this.Van = false),
        (this.Flatbed = false),
        (this.Reefer = false),
        (this.Specialized = false),
        (this.Size48 = false),
        (this.Size53 = false);
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
  },
};
</script>
<style lang="scss" scoped>
.p-subtitle {
  font-size: 20px;
  color: #181818;
  margin-bottom: 20px;
}
.btn-wrapper {
  .btn {
    padding: 8px 8px;
    .icon {
      width: 15px;
      height: 15px;
    }
  }
  .main-btn {
    padding: 8px 8px;
    .icon {
      fill: #fff;
      width: 15px;
      height: 15px;
    }
  }
}
.loads-card {
  padding: 20px;
  border-radius: 6px;
  background: #fff;

  .available-loads-card {
    border: 1px solid #f2f2f2;
    padding: 10px;
    border-radius: 6px;
    padding-top: 20px;
    position: relative;
  }
  .destination-loads-card {
    border: 1px solid #f2f2f2;
    padding: 10px;
    padding-top: 20px;
    border-radius: 6px;
    position: relative;
  }
  .load-card {
    padding: 10px;
    padding-top: 20px;
    border-radius: 6px;
    border: 1px solid #f2f2f2;
    position: relative;
  }
  .origin-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background: #f9f3e7;
    position: absolute;
    top: -15px;
    left: 26px;
    padding: 5px 15px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    font-size: 13px;
    .icon {
      margin-right: 6px;
    }
  }
  .destination-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background: #e8f8d6;
    position: absolute;
    top: -15px;
    left: 26px;
    padding: 5px 15px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    font-size: 13px;
    .icon {
      margin-right: 6px;
    }
  }
}
</style>
